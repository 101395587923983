import Template, { Grid, Main, BasicNav } from "@mojo/blueprint/Template";
import {
  LoginContainer,
  LoginFormContainer,
  LoginSuccess,
} from "../../styles/Login.css";
import Typography from "@mojo/blueprint/Typography";
import Form from "@mojo/blueprint/Form";
import Input from "@mojo/blueprint/Input/Controlled";
import Button from "@mojo/blueprint/Button";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import { useDevLogin, useLogin } from "@mojo/auth/react";
import { useState } from "react";
import { Check } from "@mojo/blueprint/Icon";
import { useTrackPageView } from "@mojo/analytics";
import { useTemplate } from "@mojo/blueprint/Template/useTemplate";

export const Route = createFileRoute("/login")({
  component: () => (
    <Template variant="gray-wave">
      <Login />
      <Outlet />
    </Template>
  ),
  validateSearch: (search: Record<string, unknown>) => {
    const redirect = !((search.redirect as string) || "").startsWith("http")
      ? `${window.location.protocol}//${window.location.host}${search.redirect || "/dashboard"}`
      : (search.redirect as string);
    return {
      redirect,
    };
  },
});

/**
 * Renders a login page
 */
function Login() {
  useTrackPageView(
    `${window.location.protocol}//${window.location.host}/login${window.location.search}`
  );

  const { navHeight } = useTemplate();

  const [success, setSuccess] = useState(false);

  const { redirect } = Route.useSearch();
  const { sendLink, isMutating } = useLogin(redirect, {
    onSuccess: () => {
      setSuccess(true);
    },
  });
  const { devLogin } = useDevLogin({
    redirect:
      decodeURIComponent(redirect) ||
      `${window.location.protocol}//${window.location.host}/dashboard`,
  });

  return (
    <>
      <BasicNav variant="transparent" />
      <Main
        style={{
          flex: "1 1 0%",
        }}
      >
        <Grid
          style={{
            alignItems: "center",
          }}
        >
          <div
            className={LoginContainer}
            style={{
              marginTop: -navHeight,
            }}
          >
            <Typography.H1>Log in</Typography.H1>
            <Typography.Body>
              Enter your email and we’ll send you a link to log in
            </Typography.Body>
            <div className={LoginFormContainer}>
              {success && (
                <div className={LoginSuccess}>
                  <Check width={16} height={16} />
                  <Typography.Body weight={700} as="span">
                    Done! Check your inbox for a login link.
                  </Typography.Body>
                </div>
              )}
              <Form
                id="login-form"
                onSubmit={({ email }) => {
                  sendLink(email);
                }}
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={{
                    required: {
                      value: true,
                      message: "Please tell us your email",
                    },
                    pattern: {
                      value:
                        // eslint-disable-next-line no-control-regex
                        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                      message: "Please use a valid email.",
                    },
                  }}
                >
                  <Input placeholder="Email address" />
                </Form.Item>
              </Form>
              <Button
                form="login-form"
                type="submit"
                style={{
                  width: "100%",
                }}
                isLoading={isMutating}
              >
                Send login link
              </Button>
              {import.meta.env.MODE !== "prod" && (
                <button
                  onClick={async () => {
                    await devLogin();
                  }}
                >
                  <Typography.Body weight={600} as="span">
                    💻 I am devloper
                  </Typography.Body>
                </button>
              )}
            </div>
          </div>
        </Grid>
      </Main>
    </>
  );
}
