const getEnv = () => {
  // @ts-ignore
  if (typeof process === "undefined") {
    try {
      // @ts-ignore
      return import.meta.env;
    } catch (err) {
      throw err;
    }
  }
  // @ts-ignore
  return process.env;
};

const isProd = getEnv().IS_OFFLINE !== "true";

/**
 * Project-level configuration required to use auth
 */
export const config = {
  /**
   * Only to be called from the backend, connects to the VPC auth API
   */
  AUTH_API: isProd ? getEnv().AUTH_API : "http://localhost:4020",
  /**
   * To be called from the frontend
   */
  PUBLIC_AUTH_API:
    getEnv().PUBLIC_AUTH_API ||
    getEnv().VITE_PUBLIC_AUTH_API ||
    getEnv().NEXT_PUBLIC_AUTH_API,
  sessionCookie: `${isProd ? "__Secure-" : ""}session-token`,
};

/**
 * To improve DX we print out warnings at runtime
 * if we can't find environment variables required
 * to use the auth API
 */
const checkEnvironment = () => {
  const env = getEnv();

  const isPublicAuthDefined = Boolean(config.PUBLIC_AUTH_API);
  const isAuthDefined = Boolean(config.AUTH_API);
  const isStageDefined = Boolean(env.STAGE);
  // window is only defined on the client
  // @ts-ignore
  const isClient = typeof window !== "undefined";

  // only defined on vite
  const isVite = Boolean(env.MODE);
  const isNext = isClient
    ? // @ts-expect-error defined if next on client
      Boolean(window.next)
    : false;

  // if window is defined, we're running on a client
  if (isClient) {
    if (!isPublicAuthDefined) {
      if (isVite) {
        console.warn(
          `[MOJO_AUTH]: You're running @mojo/auth in Vite but you haven't defined VITE_PUBLIC_AUTH_API. Please set the environment variable https://github.com/mojomortgages/my.mojo.monorepo/blob/main/packages/auth/README.md#-environment-variables-required-client.`
        );
      } else if (isNext) {
        console.warn(
          `[MOJO_AUTH]: You're running @mojo/auth in Next but you haven't defined NEXT_PUBLIC_AUTH_API. Please set the environment variable https://github.com/mojomortgages/my.mojo.monorepo/blob/main/packages/auth/README.md#-environment-variables-required-client.`
        );
      } else {
        console.warn(
          `[MOJO_AUTH]: You're running @mojo/auth in an unidentified runtime but you haven't defined PUBLIC_AUTH_API. Please set the environment variable https://github.com/mojomortgages/my.mojo.monorepo/blob/main/packages/auth/README.md#-environment-variables-required-client.`
        );
      }
    }
    // if not it's an API
  } else {
    if (!isAuthDefined) {
      throw Error(
        `[MOJO_AUTH]: You're running @mojo/auth in an API but you haven't defined AUTH_API. Please set the enviroment variable https://github.com/mojomortgages/my.mojo.monorepo/blob/main/packages/auth/README.md#-environment-variables-required-server.`
      );
    }
    if (!isStageDefined) {
      throw Error(
        `[MOJO_AUTH]: You're running @mojo/auth in an API but you haven't defined STAGE. Please set the enviroment variable https://github.com/mojomortgages/my.mojo.monorepo/blob/main/packages/auth/README.md#-environment-variables-required-server.`
      );
    }
  }
};

checkEnvironment();
