import React from "react";
import { TemplateContext } from "./context";
import { BasicNav } from "./Nav/Basic";
import { Grid } from "./Grid";
import { Main } from "./Main";
import { Footer } from "./Footer";
import { BrandProp } from "@mojo/types";
import { VanillaTemplate } from "./styles/base.css";

export type TemplateProps = {
  children: React.ReactNode;
  variant?: "gray" | "orange" | "yellow-wave" | "gray-wave";
} & React.HTMLAttributes<HTMLDivElement> &
  BrandProp;

/**
 * Template wraps all other templating components. It sets context,
 * changes background colors and puts things in the correct order.
 */
const Template = ({
  children,
  brand = "Mojo",
  variant = "gray",
  ...props
}: TemplateProps) => {
  const [navHeight, setNavHeight] = React.useState(0);
  const [mainHeight, setMainHeight] = React.useState(0);

  return (
    <TemplateContext.Provider
      value={{ navHeight, setNavHeight, mainHeight, setMainHeight }}
    >
      <div
        {...props}
        className={`
          mojo__template
          ${VanillaTemplate({ brand, variant })}
          ${props.className || ""}
        `}
      >
        {children}
      </div>
    </TemplateContext.Provider>
  );
};

export default Template;
export { BasicNav, Grid, Main, Footer };
