import Template, { BasicNav, Grid, Main } from "@mojo/blueprint/Template";
import { createFileRoute } from "@tanstack/react-router";
import {
  Copy,
  ExpiredContainer,
  HighOpacity,
  Illustration,
  Loading,
  LowOpacity,
  RequestButton,
  Success,
  mtVar,
  spin,
} from "../../../styles/Expired.css";
import Typography from "@mojo/blueprint/Typography";
import { useTemplate } from "@mojo/blueprint/Template/useTemplate";
import { useLogin } from "@mojo/auth/react";
import { useState } from "react";
import { Presence } from "@mojo/blueprint/Presence";
import { Check } from "@mojo/blueprint/Icon";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { useTrackPageView } from "@mojo/analytics";

export const Route = createFileRoute("/login_/expired")({
  component: () => (
    <Template variant="gray-wave">
      <Expired />
    </Template>
  ),
  // defaults redirect to be the mymojo dashboard
  validateSearch: (search: Record<string, unknown>) => {
    const redirect = !((search.redirect as string) || "").startsWith("http")
      ? `${window.location.protocol}//${window.location.host}${search.redirect || "/dashboard"}`
      : (search.redirect as string);
    const email = (search.email as string) || undefined;
    return {
      redirect,
      email,
    };
  },
});

/**
 * A page that users with expired links are sent to.
 * Here they're given the option to request a new link.
 *
 * Redirect to this page is handled by the public-auth-api.
 */
const Expired = () => {
  useTrackPageView(
    `${window.location.protocol}//${window.location.host}/login/expired${window.location.search}`
  );

  const [success, setSuccess] = useState(false);

  const { redirect, email } = Route.useSearch();
  const { navHeight } = useTemplate();

  const { sendLink, isMutating } = useLogin(redirect, {
    onSuccess: () => {
      setSuccess(true);
    },
  });

  return (
    <>
      <BasicNav variant="transparent" />
      <Main>
        <Grid>
          <div
            className={ExpiredContainer}
            // Moves the container up by the height of the nav on wide screens
            style={assignInlineVars({
              [mtVar]: `${-navHeight}px`,
            })}
          >
            <div className={Copy}>
              <Typography.H2>It looks like this link has expired</Typography.H2>
              <Typography.Body>
                Don't worry - you can{" "}
                <span>
                  <Presence present={isMutating}>
                    <span
                      className={Loading}
                      data-state={isMutating ? "open" : "closed"}
                    >
                      <svg
                        className={spin}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className={LowOpacity}
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className={HighOpacity}
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  </Presence>
                  {/* If email is undefined, links to the login page instead */}
                  {email ? (
                    <button
                      onClick={() => sendLink(email)}
                      className={RequestButton}
                    >
                      request a new link
                    </button>
                  ) : (
                    <a href="/login" className={RequestButton}>
                      login again
                    </a>
                  )}
                </span>
              </Typography.Body>
              {success && (
                <div className={Success}>
                  <Check
                    style={{
                      marginRight: "0.5rem",
                    }}
                  />
                  <Typography.Body>
                    We've sent you a new link. Check your email.
                  </Typography.Body>
                </div>
              )}
            </div>
            <div className={Illustration}>
              <img
                src={
                  "https://hosted-assets.mojomortgages.com/images/illustrations/chat.png"
                }
              />
            </div>
          </div>
        </Grid>
      </Main>
    </>
  );
};
