import { config } from "../auth.config";
import axios from "axios";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";

/**
 * dev login
 */
export const devLogin = async (redirect: string) => {
  window.location.replace(
    `${config.PUBLIC_AUTH_API}/magic-login?${new URLSearchParams(
      omitBy(
        {
          ["magicToken"]: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im15bW9qb3Rlc3QxQG1vam8uY29tIiwidXNlcklkIjo0MTU2NSwiYXBwbGljYXRpb25JZFRvTGluayI6bnVsbCwiZXhwIjozMDExOTQxNjQwMywiaWF0IjoxNzE5NDE2NDAzfQ.S7bmSxEFs4xdnhHpRk9naPSpERUomlvlW-fk-gfHfEM`,
          redirect: redirect || "",
        },
        isEmpty
      )
    )}`
  );
};

/**
 * Sends a magic link to the specified email
 */
export const sendMagicLink = async (emailAddress: string, redirect: string) => {
  await axios(`${config.PUBLIC_AUTH_API}/send-magic-login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
    data: {
      emailAddress,
      redirect,
    },
  });
};
