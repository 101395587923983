import { useController, useFormContext } from "react-hook-form";
import { useFormItem } from "../../Form";
import Input, { InputProps } from "../Input";

export interface ControlledInputProps extends InputProps {}

const InputControlled = ({ ...rest }: ControlledInputProps) => {
  const form = useFormContext();
  const { name, rules, setValueAs } = useFormItem();

  const { field, fieldState } = useController({
    name: name,
    control: form.control,
    rules,
    defaultValue: rest.defaultValue || "",
  });

  return (
    <Input
      id={field.name}
      {...rest}
      {...field}
      onChange={(e) => field.onChange(setValueAs(e.target.value))}
      className={`${fieldState.invalid ? "invalid" : ""} ${rest.className}`}
    />
  );
};

export default InputControlled;
