import useSWRMutation from "swr/mutation";
import { devLogin, sendMagicLink } from "../client";

/**
 * Login as developer as a react hook
 */
export const useDevLogin = ({ redirect }: { redirect: string }) => {
  const { trigger, ...rest } = useSWRMutation("login", () =>
    devLogin(redirect)
  );

  const mutation = async () => {
    await trigger();
  };

  return {
    devLogin: mutation,
    ...rest,
  };
};

export const useLogin = (
  redirect: string = "/dashboard",
  options?: {
    onSuccess?: () => void;
    onError?: () => void;
  }
) => {
  const { trigger, ...rest } = useSWRMutation(
    "sendMagicLink",
    (_url, { arg: email }: { arg: string }) => sendMagicLink(email, redirect),
    options
  );

  const mutation = async (email: string) => {
    await trigger(email);
  };

  return {
    sendLink: mutation,
    ...rest,
  };
};
