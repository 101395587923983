import React from "react";
import CustomGA from "./customGA4";

/**
 * Initialise the GA instance
 * @param gaId The GA measurement Id
 */
export const Analytics = (
  gaId: string,
  options?: Parameters<typeof CustomGA.initialize>[1]
) => {
  CustomGA.initialize(gaId, {
    gtagOptions: { send_page_view: false, ...options?.gtagOptions },
    ...options,
  });
};

/**
 * Send an event to the analytics
 * @param eventName The name of the event
 * @param params An object of any parameters
 */
export const Event = (eventName: string, params: object) => {
  // we pass event name as a custom parameter because in some places GA4 refuses to let you filter
  // with the built-in event name (like in reports as of 12/12/2023)
  CustomGA.event(eventName, { ...params, action: eventName });
};

/**
 * Send a pageview to the analytics. Also sets the global `page_location`.
 * Will internally check if the page value is valid.
 * @param page The page's url
 */
export const PageView = (page: string, params?: any) => {
  // This try/catch logs warnings regarding the format of the url being sent
  // you never know what might break when using `new URL` hence why
  // it's in a try/catch
  try {
    const containsDomain = page.startsWith("http");
    if (!containsDomain) {
      console.warn(
        `You should send the full url without query strings to analytics.`
      );
    }
    if (containsDomain) {
      const url = new URL(page);
      // this will set the `Document Location` property on all events
      CustomGA._gaCommand("set", "page_location", page);
      CustomGA.send({
        hitType: "pageview",
        page: url.pathname,
        page_location: page,
        ...params,
        // custom value, we'll probably remove it in the future
        full_page_url: page,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Set the user's dimension
 * @param params An object containing the user's information
 */
export const User = (params: { [key: string]: string }) => {
  CustomGA.user(params);
};

/**
 * Send a pageview from React
 * @param page The simplified page's url (e.g. /results/crm instead of /results/<friendlyId>/crm)
 */
export function useTrackPageView(page: string, params?: any) {
  React.useLayoutEffect(() => {
    PageView(page, { ...params, simplified_url: page });
  }, [page, params]);
}

/**
 * Send a component shown event to the analytics
 * @param componentName The name of the component rendered
 * @param params An object containing extra information
 */
export const ComponentShownEvent = (
  componentName: string,
  params?: { [key: string]: string }
) => {
  Event("Component Display", {
    action: "Component Display",
    event_category: "Site Interaction",
    event_type: "system_action",
    event_label: componentName,
    ...params,
  });
};

export { CustomGA };
